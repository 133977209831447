var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2eyxHAEr9VqxYTF-4cOPw"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

export const SkipRecordingErrorMessage = 'SkipRecording';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init( {
	dsn: SENTRY_DSN,
	environment: process.env.SENTRY_ENV || process.env.NEXT_PUBLIC_SENTRY_ENV,
	// Adjust this value in production, or use tracesSampler for greater control
	// tracesSampleRate: 1.0,
	// ...
	// Note: if you want to override the automatic release value, do not set a
	// `release` value here - use the environment variable `SENTRY_RELEASE`, so
	// that it will also get attached to your source maps
	beforeSend: ( event ) => {
		if (
			Array.isArray( event?.exception?.values ) &&
			event?.exception?.values.find( ( exception ) =>
				exception.value.includes( SkipRecordingErrorMessage )
			)
		) {
			// If we get here, then we skip sending this to Sentry
			return null;
		}
	},
} );
